body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}
